.gc-skeleton {
  $skeletonColor: #e9eaee;
  $skeletonLineHeight: 12px;
  position: relative;
  margin: 1em 0 1.5em 20px;
  width: calc(100% - 20px);

  &-comment {
    position: relative;
    border-radius: 10px 10px 0 0;
    height: 150px;
    border: 1px solid $skeletonColor;
    border-bottom: 0;
    padding: 20px 40px;
  }
  &-line {
    width: 80%;
    height: $skeletonLineHeight;
    margin-bottom: 12px;
    background: $skeletonColor;
    &.first {
      max-width: 100px;
      margin-bottom: 20px;
    }
    &.last {
      max-width: 150px;
    }
  }
  &-actions {
    position: absolute;
    right: 40px;
    bottom: 20px;
  }
  &-action {
    display: inline-block;
    width: 50px;
    height: $skeletonLineHeight;
    margin-left: 5px;
    background: $skeletonColor;
  }
  &-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $skeletonColor;
    position: absolute;
    top: 20px;
    left: -20px;
  }
  &-bubble-flow {
    padding: 5px 20px;
    border: 1px solid $skeletonColor;
    border-bottom: 0;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    &.last {
      border-bottom: 1px solid $skeletonColor;
      border-radius: 0 0 10px 10px;
    }
    @media screen and (max-width: 500px) {
      .gc-skeleton-bubble:nth-child(n + 5) {
        display: none;
      }
    }
  }
  &-bubble {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $skeletonColor;
    margin-right: 10px;
    margin-bottom: -5px;
  }
  &-child-comment {
    position: relative;
    padding: 20px 40px 50px 80px;
    border: 1px solid $skeletonColor;
    border-bottom: 0;
    .gc-skeleton-avatar {
      margin-left: 40px;
    }
  }
  &-footer {
    opacity: 0.5;
    max-width: 200px;
    height: $skeletonLineHeight;
    margin-top: 10px;
    margin-left: 2px;
    background: $skeletonColor;
  }
  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 20px);
    height: 100%;
    top: 0;
    left: -20px;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0.5) 25%,
      rgba(255, 255, 255, 0) 40%
    );
    background-position: -50vw;
    background-repeat: no-repeat;
    animation: slideBg 3s linear infinite;

    @keyframes slideBg {
      0% {
        background-position: -50vw;
      }
      100% {
        background-position: 100vw;
      }
    }
  }
}
