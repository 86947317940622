.shadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.searchContainer {
  @apply relative h-10 rounded-md;
}

.suggestionsContainerCommon {
  @apply overflow-y-auto w-full transition ease-in-out duration-100 transition-all;

  @media (orientation: landscape) {
    & {
      max-height: calc(100vh - 100px);
    }
  }

  @media (orientation: portrait) {
    & {
      max-height: calc(100vh - 100px);
    }
  }
}

.suggestionsContainerMobile {
  @apply relative w-full overflow-auto;
}

.suggestionsContainer {
  @apply bg-gray-lighter border  border-solid border-gray-200;
}
