// .cdx-list__item {
//   padding-top: 0 !important;
//   padding-bottom: 0 !important;
//   padding-left: 0.375em !important;
// }

// // Inspiration: https://github.com/kommitters/editorjs-drag-drop/issues/134
// .ce-block--drop-target .ce-block__content {
//   outline: 1px dotted darkgray;
// }
// .ce-block--drop-target:last-child .ce-block__content {
//   outline: 1px dotted darkgray;
// }
// .ce-block--drop-target:first-child .ce-block__content {
//   outline: 1px dotted darkgray;
// }
// .ce-block--drop-target .ce-block__content:before {
//   top: 50%;
//   left: -20px;
//   height: 8px;
//   width: 8px;
//   border: solid darkgray;
//   border-width: 2px 2px 0 0;
//   pointer-events: none;
// }
// // .ce-block--drop-target:first-child .ce-block__content:before {
// //   top: 0;
// // }
// // .ce-block--drop-target:last-child .ce-block__content:before {
// //   top: 100%;
// // }

// Improve the drag and drop styles

.ce-block--drop-target .ce-block__content:before {
  pointer-events: none;
}

.ce-block--drop-target .ce-block__content:after {
  background: none;
}

.ce-block--drop-target
  .ce-block__content[style*='border-top: 1px dashed rgb(170, 170, 170);']:before {
  top: 0;
}

.ce-scroll-locked--hard.ce-scroll-locked--hard {
  overflow: initial;
  position: initial;
}

@layer components {
  // https://stackoverflow.com/a/52536409/4053349
  // [contenteditable] {
  //   -webkit-user-select: text;
  //   user-select: text;
  // }

  .ce-toolbar {
    @apply z-[300];

    .ce-popover__overlay.ce-popover__overlay--hidden {
      display: initial;
      opacity: 0;
      visibility: hidden;
    }
  }

  .prose-editor.prose-editor {
    // Customize prose: https://tailwindcss.com/docs/typography-plugin#element-modifiers
    // lg:prose-lg
    @apply prose max-w-none prose-ol:m-0 prose-ol:py-0 prose-ul:m-0 prose-ul:py-0 first:prose-p:mt-0 prose-headings:p-0 text-gray-darkv4 leading-normal;
    // prose-headings:mt-[0.6em] prose-headings:mb-[3px]

    .ce-block__content,
    .ce-toolbar__content {
      max-width: initial;
      @apply mx-5 sm:ml-15;
    }

    .ce-block__content {
      @apply mt-5;
    }

    // Styles specific to editorjs, that should not be applied to the final rendering
    .codex-editor {
      // Min height to avoid some scrolling bugs on empty editors, e.g. when adding a new comment to a topic.
      @apply min-h-[210px] flex-1 z-auto /* overflow-y-auto overflow-x-visible */;

      .codex-editor__redactor {
        // @apply overflow-hidden;
        // y Removed to avoid an iOS bug with editorjs: tuning menu's overlay truncated
        // I originally added it, I forgot why. It may not be required anymore, now there is an overflow-y-auto + overflow-x-hidden on the wrapper.
        // @apply overflow-x-hidden;
        @apply mr-0;
      }
      .ce-popover__search {
        @apply hidden;
      }
      .ce-toolbar__plus,
      .ce-toolbar__settings-btn {
        color: #b7bcc4;
      }
      .ce-block {
        transition: background-color 0.15s ease;
      }
      .ce-block--selected {
        background: #e1f2ff;
      }

      .cdx-block {
        @apply py-0;
        word-break: break-word;
      }

      .ce-paragraph,
      .paragraph,
      .image-tool,
      .link-tool,
      .cdx-quote {
        @apply py-2;
      }
      h2 {
        @apply m-0 pt-9 pb-3 font-semibold;
      }
      h3 {
        @apply m-0 pt-3 pb-0;
      }
      h4 {
        @apply m-0 pt-3 pb-0;
      }
      .cdx-list__item {
        @apply my-0 pl-[6px] py-1;
      }
      .image-tool__image-picture {
        @apply my-0;
      }
      .cdx-quote {
        @apply my-0;
      }
      .cdx-quote__caption {
        @apply hidden;
      }
      .cdx-quote .cdx-quote__text {
        @apply border-none shadow-none p-0;
        min-height: auto;
      }
      .image-tool__image {
        resize: horizontal;
        position: relative;
        border: 2px solid #0096fd;
        @apply mb-0 inline-block max-w-full;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          height: 8px;
          width: 8px;
          background-color: #0096fd;
        }
      }
      .image-tool__image-picture {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .ce-tune-alignment--center .image-tool__image {
        margin-left: auto;
        margin-right: auto;
      }
      .ce-tune-alignment--right .image-tool__image {
        margin-left: auto;
      }
      .image-tool__caption {
        @apply hidden;
      }
      .image-tool--empty .image-tool__image {
        display: none;
      }
      // If we just want to hide the rotating loader instead:
      // .image-tool--empty .image-tool__image-preloader::after {
      //   display: none;
      // }
    }
  }
}

.MessageContent {
  > .link-tool,
  > .gform-tool {
    &:not(:first-child) {
      @apply mt-4 md:mt-6;
    }
    &:not(:last-child) {
      @apply mb-4 md:mb-6;
    }
  }

  &.link-highlight {
    .link-tool {
      &__content {
        flex-direction: column;
      }

      &__image {
        flex-direction: column;
        width: 100%;
        height: auto;
        aspect-ratio: 4/3;
      }
    }
  }
}

.ban-banner {
  background: #f8f3d6;
  border: 1px solid #d8cd85;
  color: #94723c;
  padding: 0.7em 1em;
  box-sizing: border-box;
  border-left: 0;
  border-right: 0;
  position: relative;
}
