.relative {
  position: relative;
}
.right {
  float: right;
}
.semibold {
  font-weight: $semiBold;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.no-outline {
  outline: none;
}

// hide recpatcha logo
.grecaptcha-badge {
  display: none !important;
}

.invisible-scrollbar {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}

.icon {
  @at-root {
    a#{&} {
      cursor: pointer;
    }
  }

  &-text {
    clip: rect(0 0 0 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
  }
}

.avatar {
  display: flex;
  align-items: center;
  font-weight: bold;
  box-sizing: content-box;

  img {
    width: 25px;
    height: 25px;
    margin-right: 6px;
    object-fit: cover;
  }

  span {
    color: $colorDefault;
  }
}

.loader {
  background-image: url('/img/loader.gif');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  margin: 0 auto;
  display: inline-block;

  &-inline {
    margin: 0 10px;
  }

  &-centered {
    margin: 10px auto;
    display: flex;
  }
}

.filter {
  color: $colorDefault;
  cursor: pointer;
  white-space: nowrap;

  b {
    margin-left: 2px;
    color: RGB(var(--primary-color));
  }

  i {
    font-size: 8px;
    margin-left: 6px;
  }

  &:focus {
    outline: none;
  }
}

.tag {
  font-size: 11px;
  background: #fcedeb;
  border-radius: 10px;
  color: #e55237;
  font-weight: 400;
  padding: 3px 8px 4px;
  cursor: pointer;
  white-space: nowrap;

  @include mobile {
    padding: 7px 7px 8px;
    font-size: 14px;
  }

  &-small {
    padding: 2px 8px;
  }

  &-big {
    font-size: 13px;
    padding: 5px 8px 6px;

    @include mobile {
      font-size: 15px;
      padding: 8px 15px;
    }
  }
}

.add-tag {
  font-size: 11px;
  font-weight: 400;
}

.tags-list {
  li {
    display: inline-block;
  }

  a {
    margin-right: 8px;
  }
}

.show-all-tags {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;

  a {
    color: RGB(var(--primary-color));
    text-decoration: underline;
    font-weight: bold;
  }
}

.label {
  display: inline-flex;
  align-items: center;
  color: $colorGrey400;
  font-size: 11px;
  text-transform: uppercase;
  padding: 2px 4px 1px;

  &-default {
    background-color: $colorGrey400;
    color: #fff;
    border-radius: 2px;
  }

  &-orange {
    background-color: $colorOrange;
    color: #fff;
    border-radius: 2px;
  }

  i {
    margin-right: 2px;
  }
}

.badge {
  top: 0;
  right: 0;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  color: #fff;
  background-color: rgb(220, 0, 78);
  font-family: monospace;
  height: 18px;
  display: flex;
  padding: 0 6px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  min-width: 18px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-weight: bold;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
}

.show-more,
.msg {
  width: 100%;
  display: block;
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px rgba(229, 82, 55, 0.14);
  background-color: rgba(229, 82, 55, 0.05);
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  color: RGB(var(--primary-color));

  &:not(p):not(div) {
    &:hover {
      background-color: rgba(229, 82, 55, 0.065);
    }
  }

  &-notice {
    color: $colorDefault;
    background: $colorGrey100;
    border: none;
    text-align: left;
    opacity: 0.8;

    &:not(p):not(div) {
      &:hover {
        background: $colorGrey200;
      }
    }
  }

  &-success {
    color: $colorGreen;
    background-color: $colorGreenBg;
    border: none;
    text-align: left;
  }
}

.success {
  color: $colorGreen;
}

.error {
  color: RGB(var(--primary-color));
}

.avatar {
  border: 1px solid $colorGrey200;
  border-radius: 50%;
  padding: 2px;
  margin-left: -3px;
  margin-top: -3px;
  box-sizing: content-box;
}

.error {
  color: RGB(var(--primary-color));
  font-weight: bold;
}

.three-dots {
  font-size: 32px;
  color: $colorGrey400;
  line-height: 0;
}

.circle {
  font-size: 10px;
  color: $colorGrey300;
}

@mixin grid($marginRight, $marginBottom, $itemsPerLine: 3) {
  width: calc(100% + #{$marginRight});
  margin-left: -$marginRight;

  > * {
    display: inline-flex;
    width: calc(100% / #{$itemsPerLine} - #{$marginRight} - 1px); // 1px for IE Edge18
    margin-bottom: $marginBottom;
    margin-left: $marginRight;
    box-sizing: border-box;

    @media (max-width: 750px) {
      width: calc(50% - #{$marginRight});
    }

    @media (max-width: 450px) {
      width: calc(100% - #{$marginRight});
    }
  }
}
