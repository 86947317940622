.break {
  word-break: normal;
  overflow-wrap: anywhere;
}

.reverser {
  transform: scaleY(-1);

  > * {
    transform: scaleY(-1);
  }

  @apply min-h-full px-2;
}

.msgItem {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}