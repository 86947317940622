.emoji {
  color: transparent;
  margin: 0 2px;
  font-size: 13px;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: text-bottom;
  width: 19px;
  height: 19px;

  @include mobile {
    width: 28px;
    height: 28px;
  }

  &.animated:hover {
    transform: scale(1.5);
    transition: transform 120ms ease-in;
    border-radius: 50%;
    box-shadow: 0 0 2px 2px #fff;
  }
}
