.container {
}

.squareAction {
  @apply transform transition-transform duration-200 outline-none w-11 h-11 flex items-center justify-center;

  &:nth-child(2) {
    @apply delay-75;
  }
}

.square {
  @extend .squareAction;
  @apply pointer-events-none;
}

.animated {
  // @screen md {
  //   @apply translate-x-50;
  // }
}

:global(.group):hover {
  .animated {
    @screen md {
      @apply translate-x-0;
    }
  }
}

.animatedAction {
  @extend .animated;
  @extend .squareAction;
}
