.container {
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.4);

  @apply grow shrink-0 flex flex-col items-stretch h-full overflow-hidden max-w-full bg-white;
  // h-full to remove if applying height below
  // height: 100vh;
  // height: 100svh;
  // height: var(--viewport-height);

  @screen sm {
    height: 100vh;
    max-height: 450px;
    max-width: 360px;

    @apply ml-4 rounded-t-lg;
  }
}

// .topContainer {
//   height: 100vh;
//   height: 100svh;
//   height: var(--viewport-height);
//
//   @screen sm {
//     height: 100vh;
//     max-height: 450px;
//   }
// }
