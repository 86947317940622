$votePopinBreak: 600px;

.vote-popover {
  padding: 0;

  .tippy-content {
    padding: 0;
  }
}

.votes-tooltip {
  .tippy-content > div {
    display: flex;
    align-items: center;
  }

  .all {
    display: inline-block;
    margin-right: 2px;
    font-size: 1.2em;
    line-height: 0.8;
    vertical-align: text-top;
  }
}

.vote-slider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 40px 30px 40px;
  border-radius: 0 0 5px 5px;
  min-height: 14em;
  font-size: 14px;

  .rc-slider-mark-text {
    left: -50px;
    min-width: 150px;
    width: max-content;
  }
}

.vote-slider,
.vote-boost {
  .rc-slider-handle {
    border: 0.5px solid #bebebe;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0 4px rgb(94 104 121 / 70%);
    height: 14px;
    width: 14px;
    opacity: 1;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      background-color: transparent;
      height: 50%;
      width: 50%;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 0.5px solid #bebebe;
      border-radius: 50%;
      box-shadow: inset -1px -1px 4px rgb(255 255 255 / 45%),
        inset 1px 1px 4px rgb(94 104 121 / 30%);
    }

    &.rc-slider-handle-dragging {
      border-color: #2db7f5;
      box-shadow: 0 0 0 1px #96dbfa;
    }
  }

  .rc-slider-mark {
    z-index: 1;

    & > * {
      z-index: 1;
    }
  }
}

.vote-required {
  &:after {
    left: initial;
    right: 80px;
    top: 0;
    transform: translate(0%, -50%) rotate(315deg);
  }
}

.vote-notice {
  background: #f9f9f9;
  padding: 22px 14px;
  border-radius: 0.4375em 0 0 0.4375em;
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  box-sizing: border-box;

  &-inner {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 15px;
    min-width: 220px;
    box-sizing: border-box;

    @media (max-width: $votePopinBreak) {
      padding: 0;
      border: none;
    }
  }

  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  &-text {
    color: #a5a5a5;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &-username {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;

    // truncate text if too long
    max-width: 9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-ask {
    margin-bottom: 10px;
    font-size: 15px;
    color: #5a5a5a;
    font-weight: bold;
  }

  &-nope {
    font-size: 13px;
    text-decoration: underline;
    color: #5a5a5a;
  }

  @media (max-width: $votePopinBreak) {
    flex-direction: column;
  }

  @media (min-width: $votePopinBreak + 1px) {
    &-group {
      flex-direction: column;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: $votePopinBreak) {
    padding-bottom: 10px;

    &-inner {
      align-items: flex-start;
    }

    &-text {
      margin-bottom: 0;
      letter-spacing: 0;
    }

    &-username {
      margin-bottom: 10px;
      font-size: 15px;
    }

    &-text,
    &-username {
      margin-left: 60px;
    }

    &-group {
      margin: 10px auto 0;
    }

    &-ask {
      font-size: 13px;
    }

    img {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 45px;
      height: 45px;
    }
  }
}

.vote--message {
  background: #eee;
  padding: 1em 2em 1em 1em;
  border-radius: 0.4375em 0.4375em 0 0;
  text-align: left;
  display: flex;
  align-items: center;

  img {
    width: 2.5em;
    height: 2.5em;
    border-radius: 5px;
  }

  &-right {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  &-text {
    color: #a5a5a5;
    font-size: 12px;
  }

  &-username {
    font-weight: bold;

    // truncate text if too long
    max-width: 9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.vote-zero {
  font-weight: bold;
  color: #cccccc;
}

.vote-values {
  margin-top: 5px;
}

.vote-labels {
  margin-left: 14px;

  li {
    position: relative;
    text-align: left;
    line-height: 1.9em;

    &,
    & button {
      font-family: arial, sans-serif !important;
      font-size: 14px !important;
      color: #929292 !important;
      font-weight: 200 !important;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -15px;
      top: 50%;
      width: 5px;
      border-top: solid 1px #929292;
    }

    &:hover {
      color: #f36c62;
      cursor: pointer;
    }
  }
}

.vote-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 180px;
}

.vote-close {
  position: absolute;
  top: 10px;
  right: 10px;

  i {
    color: $colorGrey400;
  }
}

.votes-summary {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  &:empty {
    display: none;
  }

  .count {
    margin-left: 2px;
  }

  [class^='icon-'] {
    @for $i from 1 through 100 {
      &:nth-child(#{$i}) {
        z-index: 4 - $i;
        border-radius: 50%;
        padding: 2px;
        position: relative;
        background: #fff;

        @if $i > 1 {
          margin-left: -6px;
        }
      }
    }
  }
}
