.title {
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 16px;

  @apply px-2 py-1 rounded-lg rounded-t-none text-white font-bold z-10;
}

.listElement {
  min-width: 250px;
  // height: 205px;
  overflow: hidden;
  // max-height: 350px;
  // content-visibility: auto;
  // contain-intrinsic-size: 200px;

  // @screen md {
  //   min-width: 26%;
  // }

  // @screen lg {
  //   contain-intrinsic-size: 250px;
  // }

  @apply rounded-lg flex-1 flex flex-col bg-top bg-no-repeat bg-cover;
  // @apply rounded-lg flex-1 flex flex-col bg-top bg-no-repeat bg-cover @lg:h-[289px] @2xl:h-[345px];
}
