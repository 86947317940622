.button {
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 12px;
  cursor: pointer;
  display: block;
  text-align: center;

  @include mobile {
    font-size: 14px;
  }

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2px $colorGrey400;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  // size

  &-large {
    padding: 10px 24px;
    font-size: 14px;
  }

  &-small {
    padding: 6px 12px;
    font-size: 12px;
  }

  &-tiny {
    padding: 4px 8px;
    font-size: 12px;
  }

  // colors

  &-default {
    background: #fbfbfb;
    color: $colorDefault;
    border: 1px solid $colorGrey200;

    &:hover {
      border: solid 1px $colorGrey400;
      background: #fff;
    }
  }

  &-primary,
  &-change-pass {
    background: RGB(var(--primary-color-variant));
    color: #fff;
    border: none;
  }

  &-change-pass[disabled] {
    background: #e9e9e9;
    color: #c1c1c1;
  }

  &-success {
    background: $colorGreen;
    color: #fff;
    border: none;
  }

  // extras

  &-invert {
    color: RGB(var(--primary-color-variant));
    background: #fff;
    border: none;

    &:hover,
    &:focus {
      background: #fff;
      border: none;
    }
  }

  &-link {
    color: $colorDefault;
    background: none;
    border: none;
    padding: 8px 12px;

    &:focus {
      box-shadow: none;
    }
  }

  &-full {
    width: 100%;
    box-sizing: border-box;
  }

  &-right {
    float: right;
  }

  &-loading {
    display: flex;
    align-items: center;

    &:after {
      @extend .loader;
      width: 14px;
      height: 14px;
      content: '';
      margin-left: 10px;
    }
  }

  &-inline {
    display: inline-block;
    margin-left: 10px;
  }
}

.input {
  display: block;
  width: 100%;
  font-size: 14px;
  margin-bottom: 12px;
  outline: none;
  padding: 11px;
  box-sizing: border-box;
  border: 1px solid $colorGrey400;
  color: $colorDefault;
  border-radius: 3px;

  &.small,
  &-small {
    padding: 5px;
  }

  &-tiny {
    padding: 2px 5px;
  }

  &:focus {
    border-color: $colorDefault;
  }
}

select.input {
  height: 35px;
  line-height: 35px;
  padding-top: 0;
  padding-bottom: 0;
}

textarea.input {
  width: 100%;
  min-height: 80px;
  font-size: 15px;
  line-height: 1.47;
  color: $colorDefault;
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: $colorGrey400;
  font-weight: normal;
  opacity: 1;
}

.input-counter {
  font-size: 12px;
  color: $colorGrey400;
  position: absolute;
  bottom: 10px;
  right: 10px;

  &-limit-reached {
    color: RGB(var(--primary-color));
  }
}

.field-label {
  display: block;
  font-size: 15px;
  margin-bottom: 20px;

  input,
  select {
    margin: 0;
    margin-top: 8px;
    margin-right: 6px;
  }
}

.form-error {
  @extend .error, .msg;
  margin: 0 0 40px;
  border-color: RGB(var(--primary-color));
}

.msg {
  .subAction {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: RGB(var(--primary-color));
    text-decoration: underline;
    font-size: smaller;
    font-weight: 500;

    &-success {
      color: $colorGreen;
    }
  }
}
