/* I found it more readable to write all ads position styles here insteads of 3 lines of tailwind classes in the className attribute (with repeating prefix before:xxx before:xxx...). */
.bgAds:before {
  z-index: -2;
  content: '';
  @apply bg-gray-50 absolute inset-0 rounded-md;
}

.bgAds:after {
  z-index: -1;
  content: 'Ad';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @apply absolute px-2 rounded-md bg-gray-500 text-white;
}
