.container {
  @apply relative w-14 h-14 cursor-pointer outline-none;

  button {
    @apply hidden;
  }

  &:hover button {
    @apply block;
  }

  &:focus {
    @apply outline-none;
  }
}
