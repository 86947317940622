// react-component
@import 'rc-switch/assets/index.css';
@import 'rc-slider/assets/index.css';

// nprogress
@import 'nprogress/nprogress.css';

// toastify
@import 'react-toastify/dist/ReactToastify.css';

// draft js
// @import 'draft-js/dist/Draft.css';
// @import '@draft-js-plugins/static-toolbar/lib/plugin.css';
// @import '@draft-js-plugins/inline-toolbar/lib/plugin.css';

// tippy js
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/material.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/themes/light-border.css';

.DraftEditor-root + div {
  @apply bg-gray-200;
  box-shadow: none;
}

.tippy-tooltip.tippy-align-center {
  text-align: center;
}

.tippy-tooltip.tippy-small {
  font-size: 12px;
}

.tippy-no-padding {
  padding: 0 !important;

  .tippy-content {
    padding: 0 !important;
  }
}

.tippy-tooltip.material-theme {
  background-color: $colorGrey500;
}

.tippy-tooltip.light-border-theme {
  & [data-placement^='bottom'] {
    > .tippy-arrow {
      border-bottom-color: $colorGrey200;

      &:after {
        border-bottom-color: $colorGrey200;
      }
    }
  }
}

.tippy-tooltip.material-theme {
  .tippy-content {
    color: #fff;
  }
}

.tippy-tooltip.blue-theme {
  background-color: var(--color-blue-100);
  color: var(--color-blue-300);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;

  .tippy-content {
    padding: 4px 7px;
  }

  &[data-placement^='top'] .tippy-arrow {
    border-top-color: var(--color-blue-100);
  }

  &[data-placement^='bottom'] .tippy-arrow {
    border-bottom-color: var(--color-blue-100);
    border-width: 0 8px 8px;
  }

  &[data-placement^='left'] .tippy-arrow {
    border-left-color: var(--color-blue-100);
  }

  &[data-placement^='right'] .tippy-arrow {
    border-right-color: var(--color-blue-100);
  }
}

//@mixin tippy-arrow($hook, $color) {

//& > .tippy-arrow {
//  #border- {
//    $property
//  }
//
//  -color: $color;
//}

//  #&.tippy-{$color} {
//
//}
//}

.tippy-tooltip.header-theme {
  background-color: var(--color-white);
  // font-family: 'Source Sans Pro', serif;
  color: var(--color-blue-300);
  border-radius: 0.375rem;
  box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.1);

  @extend .tippy-no-padding;

  .tippy-arrow {
    width: 0;
    height: 0;
    border-width: unset;
    top: -15px;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent;
  }

  &.tippy-red {
    .tippy-arrow {
      border-bottom-color: var(--color-red-100);
      //border-left-color: var(--color-red-100);
    }
  }

  &.tippy-blue {
    .tippy-arrow {
      border-bottom-color: var(--color-blue);
      //border-left-color: var(--color-blue);
    }
  }

  &.tippy-yellow {
    .tippy-arrow {
      border-bottom-color: var(--color-yellow-100);
      //border-left-color: var(--color-yellow-100);
    }
  }
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
