$semiBold: 500;

$colorWhite: #ffffff;
$colorYellow: #ffce00;
$colorOrange: #ef8b34;
$colorGreen: #7cb72a;
$colorBrown: #94723c;
$colorNearWhite: #edf9ff;
$colorGreenBg: rgba(0, 177, 106, 0.15);
$colorBlue: #1eaae1;
$colorBlueDark: #2980b9;

$colorGrey600: #363636;
$colorGrey602: #343434;
$colorGrey500: #6a6a6a;
$colorGrey400: #a0a0a0;
$colorGrey300: #bebebe;
$colorGrey200: #ebebeb;
$colorGrey100: #fafafa;

$colorDefault: $colorGrey600;
