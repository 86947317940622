.modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  z-index: 1000;
  position: fixed;
  padding: 0 10px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.2);
  background-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4) 100%);
  animation: fadeOverlay ease-in 200ms backwards;
  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes fadeOverlay {
    0% {
      background: rgba(0, 0, 0, 0);
    }
  }

  // fix for iOS12 when scrolling
  -webkit-transform: translate3d(0, 0, 0);

  &-content {
    background: #fff;
    width: 600px;
    max-width: 90vw;
    border-radius: 10px;
  }
}

// Animate react modal show/hide
.ReactModal__Content {
  opacity: 0;
  transform: translateY(10px);
  transition: all 100ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(10px);
}
