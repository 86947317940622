@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

// Source: https://stackoverflow.com/a/6394497/4053349
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

@mixin mobile {
  @media (max-width: 767px) {
    @if & {
      @at-root html & {
        @content;
      }
    } @else {
      @content;
    }
  }
}

@mixin desktop {
  @media (min-width: 768px) {
    @if & {
      @at-root html & {
        @content;
      }
    } @else {
      @content;
    }
  }
}

@layer components {
  // Add custom utility classes here, that can use variables like tailwind themes and can be overridden by tailwind utility classes.

  html {
    @apply h-full;
  }

  html,
  body,
  #__next {
    // Flex: 1 seems to work better on iOS when the keyboard is hidden
    @apply /* h-full */ flex flex-col flex-1;
  }

  // AOL: The below CSS may fix an issue I faced, but I can't remember which one.
  // It has a major caveat: it disables the pull-to-refresh to refresh the whole page, on browser and PWA mode (which is not acceptable, especially given the app is not super stable with its front-end cache).
  // html,
  // body {
  //   @apply overflow-hidden;
  // }
  body {
    @apply overflow-hidden text-gray-600 /* tracking-wide */;
  }

  // html {
  #__next {
    // Important: packages/front/src/services/scrollSections.tsx listens to the scroll event on this element.
    // If the scroll is moved to another element or the ID is changed, you need to also update scrollSections.
    // overflow-y-auto
    @apply pb-17 overflow-y-auto overflow-x-hidden;
  }

  h2 {
    @apply text-lg2;
  }

  h3 {
    @apply font-semibold text-[1.2rem] leading-6;
  }
}

/* Your own custom utilities */
@import 'variables';
// @import 'base';
// @import 'icons';
@import 'libs';
@import 'common'; // For consent modal
@import 'emoji';
@import 'form'; // For consent modal

// @import 'admin';
@import 'adult-consent'; // For consent modal
// @import 'ban';
// @import 'comment-list';
@import 'comment-skeleton';
// @import 'comment';
// @import 'editor';
// @import 'embed';
// @import 'inbox';
// @import 'layout';
@import 'menu';
@import 'modal'; // For consent modal
// @import 'page-list';
// @import 'page';
// @import 'pagination';
// @import 'preferences';
// @import 'profile';
// @import 'search';
// @import 'section';
// @import 'slider';
// @import 'tag-list';
// @import 'tag-selector';
// @import 'user-groups';
// @import 'user-friends';
@import 'vote';
// @import 'write';
@import '../editor/editorjs/editorjs-style';

.tippy-box:not([data-theme='material']) {
  color: $colorGrey600;
}

.tippy-box.tippy-header-customized {
  background-color: transparent;

  .tippy-content {
    padding: 0;
  }
  .tippy-arrow {
    z-index: 1;
  }
}
.tippy-yellow.tippy-header-customized .tippy-arrow {
  @apply text-yellow;
}
.tippy-blue.tippy-header-customized .tippy-arrow {
  @apply text-blue;
}
.tippy-red.tippy-header-customized .tippy-arrow {
  @apply text-red-100;
}

// Extend material theme, especially for the invite popover
// Selector 3 times to override author-popover-root which overrides the padding otherwise. It is not the cleanest approach, but the various scenarios involving one, the other or the two classes need be checked before changing it.
// The bug fixed with 3 times repetition: the invite friend sub-popup inside the profile popup should keep its margin.
// And I guess the author-popover-root selector is for the parent popover, which should not have padding.
// A cleaner approach could be to use props, if not too hard to refactor.
.tippy-add-friend-override.tippy-add-friend-override.tippy-add-friend-override {
  &.tippy-box[data-theme~='material'] {
    background-color: #ffffff;
    border: solid 1px #e9e9e9;
    border-radius: 10px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.0785894);
  }
  &.tippy-box[data-theme~='material'][data-placement^='top'] > .tippy-arrow:before {
    border-top-color: #e9e9e9;
    bottom: -8px;
  }
  &.tippy-box[data-theme~='material'][data-placement^='bottom'] > .tippy-arrow:before {
    border-bottom-color: #e9e9e9;
    top: -8px;
  }
  & .tippy-content {
    padding: 20px;
  }
}

input[type='file'].invisible {
  position: absolute;
  top: 0;
  left: -99999px;
  opacity: 0;
}
