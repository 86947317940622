.arrowContainer,
.hiddenArrowContainer {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  @apply shrink-0 flex items-center justify-center text-center text-lg font-bold text-white outline-none;

  span {
    background-color: white;
    color: black;

    @apply p-1 py-2;

    :global .icon-chevron-left {
      margin-right: 3px;
    }

    :global .icon-chevron-right {
      margin-left: 3px;
    }
  }
}

.hiddenArrowContainer {
  opacity: 0;
  pointer-events: none;
}
