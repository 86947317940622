.tippy-tooltip.menu-popover {
  padding: 0;

  .tippy-content {
    padding: 0;
  }
}

.menu {
  text-align: left;
  min-width: 150px;

  &:empty {
    display: none;
  }

  li {
    &:not(:last-child) {
      border-bottom: 1px solid $colorGrey200;
    }

    &:hover,
    &.menu-selected {
      background: $colorNearWhite;

      &:first-child {
        border-radius: 6px 6px 0 0;
      }

      &:last-child {
        border-radius: 0 0 6px 6px;
      }
    }
  }

  a,
  button {
    color: $colorDefault;
    display: block;
    padding: 10px;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    outline: none;

    i {
      color: $colorGrey400;
      margin-right: 4px;
    }
  }
}
