$discussionWidth: 280px;

.discussions {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  z-index: 10;

  @include mobile {
    width: calc(100vw - 5px);
  }
}

.discussion {
  margin-right: 10px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.06);

  &.is-reduced {
    background: #fff;
    border-radius: 8px 8px 0 0;
    border: solid 1px #e0e0e0;
    min-width: 150px;
    padding: 0 10px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: $colorGrey100;
    }

    button {
      outline: none;
    }

    i {
      font-size: 8px;
      color: $colorGrey400;
    }

    .badge {
      position: relative;
      transform: none;
      margin-left: 5px;
    }

    > * {
      &:first-child {
        display: flex;
        flex: 1;
        margin-right: 10px;
        height: 100%;
      }
      &:last-child {
        display: flex;
        flex: 0;
        padding: 5px;
      }
    }
  }

  &.is-opened {
    border: none;
    width: $discussionWidth;
    flex-basis: $discussionWidth;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

    @include mobile {
      width: 100%;
      flex-basis: auto;
    }

    button {
      outline: none;
    }
  }

  &-header {
    position: relative;
  }

  &-buttons {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;

    button {
      font-size: 8px;
      padding: 0 10px;
      color: $colorGrey400;

      &:not(:first-child) {
        border-left: 1px solid $colorGrey200;
      }
    }
  }

  &-content {
    background: #fff;
  }

  &-recipients {
    border-bottom: solid 1px #e9e9e9;
    display: flex;
    align-items: center;

    .tippy-popper {
      width: $discussionWidth;

      .msg {
        margin: 0;
      }
    }

    &-add {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      height: 40px;
      box-sizing: border-box;
      outline: none;

      i {
        color: $colorGrey400;
        margin-right: 4px;
      }

      &.is-disabled {
        cursor: default;
        i {
          display: none;
        }
      }
    }

    .tippy-tooltip {
      .tippy-arrow {
        left: 205px !important;
      }
      .menu {
        width: $discussionWidth - 10px;
        max-height: 125px;
        overflow: scroll;

        li {
          border-bottom: none;
        }

        button {
          font-size: 14px;
          display: flex;
          align-items: center;
          padding: 5px 20px 5px 10px;
        }

        img {
          width: 26px;
          margin-right: 5px;
          border-radius: 50%;
        }
      }
    }

    &-list {
      padding: 5px 10px 4px 10px;
    }

    &-recipient {
      font-size: 14px;
      font-weight: $semiBold;
      display: flex;
      align-items: center;
      color: $colorDefault;

      @include mobile {
        line-height: 38px;
      }

      time {
        font-weight: normal;
        margin-left: 6px;
        display: inline-block;
        font-size: 12px;
      }

      img {
        width: 18px;
        border-radius: 50%;
        margin-right: 6px;
      }
    }

    &-placeholder {
      color: $colorGrey400;
    }

    &-input {
      border: none;
      height: 40px;
      display: flex;
      flex: 1;
      outline: none;
      padding: 5px 10px 4px 10px;
    }
  }

  &-messages {
    min-height: 200px;
    max-height: 200px;
  }

  &-time {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 2px;
    color: #9c9d9e;
    margin-right: 5px;
  }

  &-message {
    padding: 10px;
    border-radius: 8px;
    background: #f0f0f0;
    margin: 8px 8px 0;
    font-size: 15px;

    &.is-mine {
      color: #fff;
      background: #5baff2;
      margin-left: 16px;
    }

    &.is-other {
      margin-right: 16px;
    }

    &.is-image {
      background: none;
      color: $colorDefault;

      a {
        text-decoration: underline !important;
      }

      &.is-mine {
        text-align: right;
      }

      img {
        max-width: 100%;
        max-height: 200px;
      }
    }

    &:last-child {
      margin-bottom: 20px;
    }
  }

  &-form {
    $padding: 15px;
    position: relative;
    border-top: solid 1px #e9e9e9;

    .input {
      border: none;
      background: none;
      margin-bottom: 0;
      min-height: auto;
      padding: $padding;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px $padding $padding;

      &.not-written-yet {
        position: absolute;
        bottom: 15px;
        right: 10px;
        padding: 0;
      }
    }

    &-buttons {
      display: flex;
    }

    &-actions {
      margin-left: auto;

      button,
      label {
        color: #888;
        font-size: 18px;
        vertical-align: bottom;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}
