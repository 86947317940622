.adult-consent {
  &-close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: $colorGrey400;
  }

  &-title {
    color: RGB(var(--primary-color));
    font-size: 24px;
    margin: auto auto 30px;
    max-width: 60%;
  }

  &-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &-explain {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &-buttons {
    display: flex;

    button {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      font-size: 13px;
      padding: 20px 10px 16px;

      @extend .button, .button-default;

      background-color: RGB(var(--primary-color-variant));
      color: RGB(var(--primary-color-contast));

      &:hover {
        background-color: RGB(var(--primary-color));
      }

      &:first-child {
        margin-right: 10px;
      }

      b {
        font-size: 18px;
        margin-bottom: 4px;
      }
    }
  }
}
