$headerMobileBreak: 500px;

.header-title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a {
    color: RGB(var(--primary-color));
  }
}

.header-menu-wrapper {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-left: 20px;
  margin-right: 4px;

  @include mobile {
    flex-direction: column;
    margin-right: 20px;
    width: 100%;
  }
}

.header-menu {
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 15px;
  margin-top: 15px;

  @media (max-width: $headerMobileBreak) {
    margin-left: 0;
    justify-content: flex-end;
  }

  &-connected {
    font-weight: 400;
  }

  &-not-connected {
    margin-left: 20px;
    margin-right: 4px;

    @include mobile {
      margin: 15px 0 0;

      .button {
        font-size: 14px;
      }
    }
  }

  > li {
    display: inline-block;

    &.header-toggle-usermenu {
      position: relative;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.has-notifs {
      margin-right: 14px;
      height: 26px;
      display: flex;
    }

    &.separator {
      display: flex;
      align-items: center;
      margin-right: 14px;

      &:after {
        content: '';
        border-right: 1px solid $colorGrey200;
        padding-right: 10px;
        height: 18px;
      }

      @include mobile {
        &:last-child:after {
          display: none;
        }
      }
    }
  }

  button {
    &:hover,
    &:focus {
      outline: none;

      i {
        color: $colorDefault;
      }
    }
  }

  > li > button > i {
    font-size: 19px;
    color: $colorGrey400;

    &.icon-msg:before {
      display: block;
      margin-bottom: -2px;
    }

    &.icon-friend-notification {
      font-size: 21px;
    }

    &.icon-notif:before {
      display: block;
      font-size: 120%;
      margin-bottom: -1px;
    }
  }

  &-user-link {
    display: inline-flex;
    align-items: center;

    img {
      width: 20px;
      border-radius: 50%;
      margin-right: 5px;

      @include mobile {
        width: 26px;
      }
    }

    span {
      font-weight: $semiBold;
    }

    i {
      font-size: 8px;
      margin-left: 6px;
    }
  }

  @include mobile {
    .tippy-popover-panel {
      max-width: none !important;
      width: auto;
    }
  }
}

.header-usermenu {
  padding: 15px 35px 25px 25px;

  @include mobile {
    padding: 15px 25px;
  }

  &-section {
    font-size: 13px;

    @include mobile {
      font-size: 15px;
    }
  }

  &-spacer {
    border-top: 1px solid $colorGrey200;
    margin: 15px 0;
  }

  &-title {
    color: $colorGrey400;
    margin-bottom: 10px;
  }

  &-list {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      white-space: nowrap;

      &:only-child {
        margin-bottom: -10px;
      }
    }

    a,
    button {
      color: $colorGrey600;

      @include mobile {
        display: flex;
        width: 100%;
        text-align: left;
        align-items: center;
        padding: 7px 0;
      }

      &:hover {
        color: RGB(var(--primary-color));
        i {
          color: RGB(var(--primary-color));
        }
      }
    }

    i {
      font-size: 13px;
      color: $colorGrey600;
      margin-right: 12px;

      @include mobile {
        font-size: 17px;
      }
    }
  }
}

.header-search {
  margin-bottom: 14px;
}
