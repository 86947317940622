@import '../../front/src/styles/globals.scss';
@import 'discussions';
@import 'header';
@import 'bubbleflow';

body {
  // @apply font-inter antialiased;
}

td {
  padding: 0;
}

.container {
  @apply _lg:px-5;
}
.ReactCollapse--collapse {
  transition: height 300ms ease-in-out;
}

#start-of-content {
  position: relative;
}

.break {
  word-break: normal;
  overflow-wrap: anywhere;
}

.fade {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade.fade-enter.fade-enter-active {
  opacity: 1;
}

.fade.fade-enter-done {
  opacity: 1;
}

.fade.fade-exit {
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
}

.fade.fade-exit.fade-exit-active {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fade.fade-exit-done {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

// Patch, so that the ads modale does not hide the message bar in the chat
// (including the button to send the message).
#um_ultimedia_wrapper_wrap_widget_default
  .um_ultimedia_wrapper_videoWrapper
  iframe#um_ultimedia_wrapper_iframeUltimedia#um_ultimedia_wrapper_iframeUltimedia.visible_player {
  bottom: 90px !important;
}

.wrapper_vpp.wrapper_vpp.active {
  bottom: 90px !important;
}

.animatedDisplay {
  display: none;
  animation: vanish 0.3s;

  &.open {
    display: block;
    animation: appear 0.3s;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes vanish {
  from {
    display: block;
    opacity: 1;
  }
  to {
    display: none;
    opacity: 0;
  }
}

.animatedSlideShow {
  display: none;
  opacity: 0;
  transition-property: display opacity transform;
  transition-duration: 0.3s;
  transition-behavior: allow-discrete;

  &.open {
    display: block;
    opacity: 1;

    @starting-style {
      opacity: 0;
    }
  }

  &.from- {
    &left-side {
      transform: translateX(-100%);
      &.open {
        transform: translateX(0);
        @starting-style {
          transform: translateX(-100%);
        }
      }
    }

    &right-side {
      transform: translateX(0);
      &.open {
        transform: translateX(-100%);
        @starting-style {
          transform: translateX(0);
        }
      }
    }
  }
}
